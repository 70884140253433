import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit } from "react-icons/fa"; // Icon for Edit
import { toast } from "react-toastify";
import { BiRupee } from "react-icons/bi";

function UserPaymentRequest() {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [editingUser, setEditingUser] = useState(null); // User currently being edited
  const [showModal, setShowModal] = useState(false); // Modal state
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  // Debounce the search input
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 500); // 500ms delay for debouncing
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  //   useEffect(() => {
  //     const fetchUsers = async () => {
  //       try {
  //         const response = await axios.post("/api/all-withdraw-request", {
  //           token: localStorage.getItem("token"),
  //         });
  //         if (response.status === 200) {
  //           setUsers(response.data.users);

  //           setLoading(false);
  //         } else {
  //           toast.error("Failed to fetch users");
  //           setLoading(false);
  //         }
  //       } catch (error) {
  //         toast.error("Error fetching users: " + error.message);
  //         setLoading(false);
  //       }
  //     };
  //     // fetchUsers();
  //   }, []);

  // Fetch users from backend based on search term (with token authentication)
  useEffect(() => {
    // Fetch users when debounced search term changes
    fetchUsers();
  }, [debouncedTerm]);

  const fetchUsers = async () => {
    try {
      const response = await axios.post("/api/all-withdraw-request", {
        token: localStorage.getItem("token"),
      });

      if (response.status === 200) {
        console.log(response.data);
        setUsers(response.data.allTransctionOfUser);
        setLoading(false);
      } else {
        toast.error("Failed to fetch users");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error fetching users: " + error.message);
      setLoading(false);
    }
  };

  // Open modal to edit user
  const openModal = (user) => {
    setEditingUser(user);
    setShowModal(true);
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setEditingUser(null);
  };

  // Handle form submission for editing user details
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `/api/edit-status-withdraw-request/${editingUser._id}`,
        {
          ...editingUser,
          token: localStorage.getItem("token"),
        }
      );
      if (response.status === 200) {
        toast.success("User updated successfully");
        setShowModal(false);
        fetchUsers();
        // Optionally refetch users or update state
      } else {
        toast.error("Failed to update user");
      }
    } catch (error) {
      toast.error("Error updating user: " + error.message);
    }
  };

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <p className="text-lg font-semibold text-gray-600">Loading users...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <h2 className="mb-4 flex text-sm sm:text-2xl font-bold text-purple-800">
        <span> Latest Payment Request </span>{" "}
        <span className="text-sm flex-1 text-gray-600">
          {" "}
          [{users?.length}]{" "}
        </span>
        <div className="flex items-center justify-center gap-2">
          <button
            onClick={() => setStatus("approved")}
            className={`border rounded px-2 text-sm 
      ${
        status === "approved"
          ? "bg-green-500 text-white"
          : "border-green-500 text-green-500 hover:bg-green-500 hover:text-white"
      }`}
          >
            Approved
          </button>
          <button
            onClick={() => setStatus("pending")}
            className={`border rounded px-2 text-sm 
      ${
        status === "pending"
          ? "bg-blue-500 text-white"
          : "border-green-500 text-green-500 hover:bg-green-500 hover:text-white"
      }`}
          >
            Pending
          </button>
          <button
            onClick={() => setStatus("rejected")}
            className={`border rounded px-2 text-sm 
      ${
        status === "rejected"
          ? "bg-red-500 text-white"
          : "border-green-500 text-green-500 hover:bg-green-500 hover:text-white"
      }`}
          >
            Rejected
          </button>
        </div>
      </h2>

      {/* Search Input */}
      {/* <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-6 w-full rounded-md border border-gray-300 p-2"
        placeholder="Search users..."
      /> */}

      {/* User List */}
      {status !== null && (
        <div className="space-y-4">
          {users?.map((user) =>
            !status || user.status === status ? (
              <div
                key={user._id}
                className="flex items-center justify-between rounded-lg bg-white p-4 shadow-md"
              >
                <div>
                  <p className="flex items-center text-gray-600">
                    {" "}
                    payment Request: {user.user.name}
                  </p>
                  <p className="flex items-center text-gray-600">
                    {" "}
                    payment Request: {user.user.email}
                  </p>
                  <p className="flex items-center text-gray-600">
                    {" "}
                    payment Request: {user.amount} <BiRupee />{" "}
                  </p>
                  <p className="flex items-center text-gray-600">
                    {" "}
                    Total balance: {user.user.amount} <BiRupee />{" "}
                  </p>

                  <p className="mt-4">Payment Requested Method</p>
                  {user.cashDetails && (
                    <div>
                      <p className="text-gray-700"> Method: Cash </p>
                      <p className="text-gray-700">
                        Name: {user.cashDetails.name}
                      </p>
                      <p className="text-gray-700">
                        Mobile Number: {user.cashDetails.mobile}
                      </p>
                    </div>
                  )}
                  {user.upiDetails && (
                    <div>
                      <p className="text-gray-700"> Method: UPI </p>

                      <p className="text-gray-700">
                        UPI ID: {user.upiDetails.upiId}
                      </p>
                    </div>
                  )}
                  {user.usdtDetails && (
                    <div>
                      <p className="text-gray-700"> Method: USDT </p>

                      <p className="text-gray-700">
                        USDT Wallet Address: {user.usdtDetails.walletAddress}
                      </p>
                    </div>
                  )}
                  {user.bankDetails && (
                    <div>
                      <p className="text-gray-700"> Method: Bank Transfer </p>

                      <p className="text-gray-700">
                        account: {user.bankDetails.account}
                      </p>
                      <p className="text-gray-700">
                        Bank Name: {user.bankDetails.bankName}
                      </p>
                      <p className="text-gray-700">
                        Holder Name: {user.bankDetails.holderName}
                      </p>
                      <p className="text-gray-700">
                        ifsc: {user.bankDetails.ifsc}
                      </p>
                    </div>
                  )}

                  <p className="mt-2">
                    {" "}
                    Date:{new Date(user.createdAt).toLocaleTimeString()},{" "}
                    {new Date(user.createdAt).toLocaleDateString()}{" "}
                  </p>
                </div>
                <button
                  onClick={() => openModal(user)}
                  className="rounded-lg bg-blue-500 p-2 text-white hover:bg-blue-600"
                >
                  <FaEdit className="inline-block" /> {user.status}
                </button>
              </div>
            ) : null
          )}
        </div>
      )}
      {status === null && (
        <div className="space-y-4">
          {users?.map((user) => (
            <div
              key={user._id}
              className="flex items-center justify-between rounded-lg bg-white p-4 shadow-md"
            >
              <div>
                <p className="flex items-center text-gray-600">
                  {" "}
                  payment Request: {user?.user?.name}
                </p>
                <p className="flex items-center text-gray-600">
                  {" "}
                  payment Request: {user?.user?.email}
                </p>
                <p className="flex items-center text-gray-600">
                  {" "}
                  payment Request: {user?.amount} <BiRupee />{" "}
                </p>
                <p className="flex items-center text-gray-600">
                  {" "}
                  Total balance: {user?.user?.amount} <BiRupee />{" "}
                </p>

                <p className="mt-4">Payment Requested Method</p>
                {user?.cashDetails && (
                  <div>
                    <p className="text-gray-700"> Method: Cash </p>
                    <p className="text-gray-700">
                      Name: {user?.cashDetails.name}
                    </p>
                    <p className="text-gray-700">
                      Mobile Number: {user?.cashDetails?.mobile}
                    </p>
                  </div>
                )}
                {user?.upiDetails && (
                  <div>
                    <p className="text-gray-700"> Method: UPI </p>

                    <p className="text-gray-700">
                      UPI ID: {user.upiDetails.upiId}
                    </p>
                  </div>
                )}
                {user.usdtDetails && (
                  <div>
                    <p className="text-gray-700"> Method: USDT </p>

                    <p className="text-gray-700">
                      USDT Wallet Address: {user?.usdtDetails.walletAddress}
                    </p>
                  </div>
                )}
                {user?.bankDetails && (
                  <div>
                    <p className="text-gray-700"> Method: Bank Transfer </p>

                    <p className="text-gray-700">
                      account: {user?.bankDetails.account}
                    </p>
                    <p className="text-gray-700">
                      Bank Name: {user?.bankDetails.bankName}
                    </p>
                    <p className="text-gray-700">
                      Holder Name: {user?.bankDetails.holderName}
                    </p>
                    <p className="text-gray-700">
                      ifsc: {user?.bankDetails.ifsc}
                    </p>
                  </div>
                )}

                <p className="mt-2">
                  {" "}
                  Date:{new Date(user.createdAt).toLocaleTimeString()},{" "}
                  {new Date(user.createdAt).toLocaleDateString()}{" "}
                </p>
              </div>
              <button
                onClick={() => openModal(user)}
                className="rounded-lg bg-blue-500 p-2 text-white hover:bg-blue-600"
              >
                <FaEdit className="inline-block" /> {user.status}
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Modal for Editing */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-lg rounded-lg bg-white p-6 shadow-lg">
            <h3 className="mb-4 text-2xl font-bold">Edit Response</h3>
            <form onSubmit={handleEditSubmit}>
              <select
                value={editingUser.status}
                onChange={(e) =>
                  setEditingUser({
                    ...editingUser,
                    status: e.target.value,
                  })
                }
                className="my-4 w-full rounded-md border border-gray-300 p-2"
              >
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>

              <div className="flex justify-between">
                <button
                  type="submit"
                  className="rounded-lg bg-green-500 px-4 py-2 text-white hover:bg-green-600"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  className="rounded-lg bg-red-500 px-4 py-2 text-white hover:bg-red-600"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserPaymentRequest;
