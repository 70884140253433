import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
// For custom styles if needed

const PlaneChange = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch transactions using Axios
  const fetchTransactions = async () => {
    try {
      const response = await axios.post("/api/transactions", {
        token: localStorage.getItem("token"),
      }); // Replace with your actual API
      if (response.status === 200) {
        setTransactions(response.data.transactions);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  // Update the status of a transaction's plan
  const handleChangeStatus = async (transactionId, newStatus) => {
    try {
      const res = await axios.put(
        `/api/transactions/setStatus/${transactionId}`,
        {
          status: newStatus,
          token: localStorage.getItem("token"),
        }
      );
      if (res.status === 200 || 202) {
        // Update the local state after the status change
        toast.success(res?.data?.message || "Unable to update transaction");
        setTransactions((prevTransactions) =>
          prevTransactions.map((transaction) =>
            transaction._id === transactionId
              ? { ...transaction, status: newStatus }
              : transaction
          )
        );
      } else {
        toast.error(res?.data?.message || "Unable to update transaction");
      }
    } catch (error) {
      toast.error(error.message || "Unable to update transaction");
      console.error("Error updating status:", error);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  if (loading) return <div>Loading transactions...</div>;

  return (
    <div className="max-w-6xl mx-auto mt-10 p-4">
      <h2 className="text-3xl font-bold text-center mb-6">Plan Transactions</h2>

      <ul className="space-y-4">
        {transactions?.map((transaction) => (
          <li
            key={transaction._id}
            className="bg-white shadow-lg border rounded-lg p-6 flex justify-between items-center transition-all hover:shadow-xl"
          >
            <div>
              <h3 className="text-xl mb-2 font-semibold text-blue-600">
                {transaction?.user?.name}, {transaction?.user?.email}, [{" "}
                {transaction?.user?.amount}₹ ]
              </h3>
              <h3 className="text-xl text-green-600 border-green-600 border inline px-2 py-1 font-semibold">
                {transaction.name} Plan
              </h3>
              <p className="text-gray-500">Price: ₹{transaction.price}</p>
              <p className="text-gray-500">
                Transaction ID: {transaction.transactionId}
              </p>

              <p className="text-sm text-gray-400">
                Time: {new Date(transaction.createdAt).toLocaleString()}
              </p>
            </div>

            {/* Dropdown to change plan status */}
            <div>
              <select
                className="border border-gray-300 rounded-lg p-2 text-sm bg-gray-100 focus:outline-none focus:border-blue-400 transition-colors"
                value={transaction.status}
                onChange={(e) =>
                  transaction.status === "approved"
                    ? null
                    : handleChangeStatus(transaction._id, e.target.value)
                }
              >
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlaneChange;
