// import axios from "axios";
// import React, { useState } from "react";
// import { toast } from "react-toastify";

// function WithdrawalPopup() {
//   const [amount, setAmount] = useState("");
//   const [paymentMethod, setPaymentMethod] = useState("");
//   const [cashDetails, setCashDetails] = useState({ name: "", mobile: "" });
//   const [upiDetails, setUpiDetails] = useState({ upiId: "" });
//   const [bankDetails, setBankDetails] = useState({
//     account: "",
//     bankName: "",
//     holderName: "",
//     ifsc: "",
//   });
//   const [usdtDetails, setUsdtDetails] = useState({ walletAddress: "" });

//   const handleWithdrawal = async () => {
//     try {
//       const res = await axios.post(`/api/withdraw-request`, {
//         amount,
//         userToken: localStorage.getItem("userToken"),
//         paymentMethod,
//         details: {
//           ...cashDetails,
//           ...upiDetails,
//           ...bankDetails,
//           ...usdtDetails,
//         },
//       });

//       if (res.status === 200) {
//         toast.success("Withdrawal request submitted successfully");
//       } else {
//         toast.error(
//           res?.data?.message || "Error submitting withdrawal request",
//         );
//       }
//     } catch (error) {
//       toast.error(error?.message || "Error submitting withdrawal request");
//     }
//   };

//   const renderPaymentFields = () => {
//     switch (paymentMethod) {

//       case "upi":
//         return (
//           <input
//             type="text"
//             className="w-full rounded-md border p-3 shadow-md"
//             placeholder="Enter UPI ID"
//             value={upiDetails.upiId}
//             onChange={(e) => setUpiDetails({ upiId: e.target.value })}
//           />
//         );
//       case "bank":
//         return (
//           <div className="space-y-3">
//             <input
//               type="text"
//               className="w-full rounded-md border p-3 shadow-md"
//               placeholder="Enter Account Holder Name"
//               value={bankDetails.holderName}
//               onChange={(e) =>
//                 setBankDetails({ ...bankDetails, holderName: e.target.value })
//               }
//             />
//             <input
//               type="number"
//               className="w-full rounded-md border p-3 shadow-md"
//               placeholder="Enter Account Number"
//               value={bankDetails.account}
//               onChange={(e) =>
//                 setBankDetails({ ...bankDetails, account: e.target.value })
//               }
//             />
//             <input
//               type="text"
//               className="w-full rounded-md border p-3 shadow-md"
//               placeholder="Enter IFSC Code"
//               value={bankDetails.ifsc}
//               onChange={(e) =>
//                 setBankDetails({ ...bankDetails, ifsc: e.target.value })
//               }
//             />
//             <input
//               type="text"
//               className="w-full rounded-md border p-3 shadow-md"
//               placeholder="Enter Bank Name"
//               value={bankDetails.bankName}
//               onChange={(e) =>
//                 setBankDetails({ ...bankDetails, bankName: e.target.value })
//               }
//             />
//           </div>
//         );
//       case "usdt":
//         return (
//           <input
//             type="text"
//             className="w-full rounded-md border p-3 shadow-md"
//             placeholder="Enter USDT Wallet Address"
//             value={usdtDetails.walletAddress}
//             onChange={(e) => setUsdtDetails({ walletAddress: e.target.value })}
//           />
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="flex bg-green-400 min-h-screen items-center justify-center  ">
//       <div className="w-full max-w-md transform rounded-lg bg-white p-6 shadow-lg transition-transform hover:scale-105">
//         <h2 className="mb-4 text-center text-2xl font-bold text-blue-600">
//           Withdrawal Form
//         </h2>
//         <div className="space-y-4">
//           <input
//             type="number"
//             className="w-full rounded-md border p-3 shadow-md"
//             placeholder="Enter Amount"
//             value={amount}
//             onChange={(e) => setAmount(e.target.value)}
//           />

//           <select
//             className="w-full  rounded-md border p-3 shadow-md"
//             value={paymentMethod}
//             onChange={(e) => setPaymentMethod(e.target.value)}
//           >
//             <option value="" disabled>
//               Select Payment Method
//             </option>

//             <option value="upi">UPI</option>
//             <option value="bank">Bank Transfer</option>
//             <option value="usdt">USDT</option>
//           </select>

//           {renderPaymentFields()}

//           <button
//             onClick={handleWithdrawal}
//             className="w-full rounded-md bg-blue-600 p-3 text-white shadow-md transition-colors hover:bg-blue-700"
//           >
//             Proceed
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default WithdrawalPopup;
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

function WithdrawalPopup() {
  const [selectedAmount, setSelectedAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [cashDetails, setCashDetails] = useState({ name: "", mobile: "" });
  const [upiDetails, setUpiDetails] = useState({ upiId: "" });
  const [bankDetails, setBankDetails] = useState({
    account: "",
    bankName: "",
    holderName: "",
    ifsc: "",
  });
  const [usdtDetails, setUsdtDetails] = useState({ walletAddress: "" });

  const handleWithdrawal = async () => {
    try {
      const res = await axios.post(`/api/withdraw-request`, {
        amount: selectedAmount,
        userToken: localStorage.getItem("userToken"),
        paymentMethod,
        details: {
          ...cashDetails,
          ...upiDetails,
          ...bankDetails,
          ...usdtDetails,
        },
      });

      if (res.status === 200) {
        toast.success("Withdrawal request submitted successfully");
      } else {
        toast.error(
          res?.data?.message || "Error submitting withdrawal request"
        );
      }
    } catch (error) {
      toast.error(error?.message || "Error submitting withdrawal request");
    }
  };

  const renderPaymentFields = () => {
    switch (paymentMethod) {
      case "upi":
        return (
          <input
            type="text"
            className="w-full rounded-md border p-3 shadow-md"
            placeholder="Enter UPI ID"
            value={upiDetails.upiId}
            onChange={(e) => setUpiDetails({ upiId: e.target.value })}
          />
        );
      case "bank":
        return (
          <div className="space-y-3">
            <input
              type="text"
              className="w-full rounded-md border p-3 shadow-md"
              placeholder="Enter Account Holder Name"
              value={bankDetails.holderName}
              onChange={(e) =>
                setBankDetails({ ...bankDetails, holderName: e.target.value })
              }
            />
            <input
              type="number"
              className="w-full rounded-md border p-3 shadow-md"
              placeholder="Enter Account Number"
              value={bankDetails.account}
              onChange={(e) =>
                setBankDetails({ ...bankDetails, account: e.target.value })
              }
            />
            <input
              type="text"
              className="w-full rounded-md border p-3 shadow-md"
              placeholder="Enter IFSC Code"
              value={bankDetails.ifsc}
              onChange={(e) =>
                setBankDetails({ ...bankDetails, ifsc: e.target.value })
              }
            />
            <input
              type="text"
              className="w-full rounded-md border p-3 shadow-md"
              placeholder="Enter Bank Name"
              value={bankDetails.bankName}
              onChange={(e) =>
                setBankDetails({ ...bankDetails, bankName: e.target.value })
              }
            />
          </div>
        );
      case "usdt":
        return (
          <input
            type="text"
            className="w-full rounded-md border p-3 shadow-md"
            placeholder="Enter USDT Wallet Address"
            value={usdtDetails.walletAddress}
            onChange={(e) => setUsdtDetails({ walletAddress: e.target.value })}
          />
        );
      default:
        return null;
    }
  };

  const amountOptions = [5000, 10000, 25000, 50000, 100000, 200000];

  return (
    <div className="flex   bg-green-400 min-h-screen items-center justify-center">
      <div className=" sm:w-[70%] w-[90%]  transform rounded-lg bg-white p-6 shadow-lg transition-transform hover:scale-105">
        <h2 className="mb-4 text-center text-2xl font-bold text-blue-600">
          Withdrawal Form
        </h2>
        <div className="space-y-4">
          <div className="flex justify-between space-x-2">
            {amountOptions.map((amount) => (
              <button
                key={amount}
                onClick={() => setSelectedAmount(amount)}
                className={`w-full rounded-md p-3 shadow-md text-white transition-colors hover:bg-blue-700 ${
                  selectedAmount === amount
                    ? "bg-blue-600"
                    : "bg-gray-500 hover:bg-gray-600"
                }`}
              >
                ₹{amount}
              </button>
            ))}
          </div>

          <select
            className="w-full rounded-md border p-3 shadow-md"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value="" disabled>
              Select Payment Method
            </option>
            <option value="upi">UPI</option>
            <option value="bank">Bank Transfer</option>
            <option value="usdt">USDT</option>
          </select>

          {renderPaymentFields()}

          <button
            onClick={handleWithdrawal}
            className="w-full rounded-md bg-blue-600 p-3 text-white shadow-md transition-colors hover:bg-blue-700"
            disabled={!selectedAmount || !paymentMethod}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
}

export default WithdrawalPopup;
