import React, { useEffect, useState } from "react";
import axios from "axios";
import { FiSend } from "react-icons/fi";
import { MdPerson, MdPhone } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { toast } from "react-toastify";

function UserManagement() {
  const [user, setUser] = useState({
    username: "",
    password: "",
    mobile: "",
  });

  const [users, setUsers] = useState(null);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/createAgent", {
        token: localStorage.getItem("token"),
        ...user,
      });
      if (response.status === 200) {
        getUsers();
        setUser({ username: "", password: "", mobile: "" });
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error creating user", error);
    }
  };

  const getUsers = async () => {
    try {
      const response = await axios.post("/api/getAgents", {
        token: localStorage.getItem("token"),
      });
      if (response.status === 200) {
        setUsers(response?.data?.agents);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);
const token=localStorage.getItem("token")
if (token!=="1234321"){
  return <div>Only Admin Can Access Agent TAB</div>;
}
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 via-green-500 to-yellow-500">
      <div className="bg-white shadow-lg rounded-lg p-10 w-full max-w-lg">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Create or Edit Agent
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex items-center space-x-2">
            <AiOutlineUser className="text-2xl text-blue-600" />
            <input
              type="text"
              name="username"
              placeholder="Username"
              value={user.username}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex items-center space-x-2">
            <MdPerson className="text-2xl text-blue-600" />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={user.password}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="flex items-center space-x-2">
            <MdPhone className="text-2xl text-blue-600" />
            <input
              type="text"
              name="mobile"
              placeholder="Mobile Number"
              value={user.mobile}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <button
            type="submit"
            className="flex items-center justify-center w-full px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <FiSend className="mr-2" /> Create User
          </button>
        </form>
      </div>

      {/* List of Users */}
      <div className="mt-12 w-full text-center">
        <h2 className="text-2xl font-bold">User List</h2>
        <ul className="mt-4 w-full max-w-xl mx-auto bg-gray-100 rounded-lg shadow-md">
          {users?.map((user, index) => (
            <li
              onClick={() => setUser(user)}
              key={index}
              className="p-4 border-b text-white last:border-b-0 rounded-lg shadow-sm bg-gray-500 hover:bg-gray-600"
            >
              <div className="flex justify-between items-center">
                <div className="text-left">
                  <span className="text-lg font-bold">{user.username}</span>
                  <p className="text-sm">{user.mobile}</p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-white">
                    <strong>Created:</strong>{" "}
                    {new Date(user.createdAt).toLocaleDateString()}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {users?.length === 0 ? (
          <p className="mt-4 text-gray-700">No users found.</p>
        ) : null}
      </div>
    </div>
  );
}

export default UserManagement;
