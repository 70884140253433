// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { FaEdit } from "react-icons/fa"; // Icon for Edit
// import { toast } from "react-toastify";

// function UserList() {
//   const [users, setUsers] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [debouncedTerm, setDebouncedTerm] = useState("");
//   const [editingUser, setEditingUser] = useState(null); // User currently being edited
//   const [showModal, setShowModal] = useState(false); // Modal state
//   const [loading, setLoading] = useState(true);
//   const [plans, setPlans] = useState([
//     {
//       name: 'Plan A',
//       price: 0,
//       tasks: 50,
//       profitPerTask: 5,
//       totalProfit: 250,
//       completedTasks: 0,
//     },
//     {
//       name: 'Plan B',
//       price: 2500,
//       tasks: 100,
//       profitPerTask: 50,
//       totalProfit: 5000,
//       completedTasks: 0,
//     },
//     {
//       name: 'Plan C',
//       price: 5000,
//       tasks: 150,
//       profitPerTask: 100,
//       totalProfit: 15000,
//       completedTasks: 0,
//     },
//     {
//       name: 'Plan D',
//       price: 10000,
//       tasks: 200,
//       profitPerTask: 150,
//       totalProfit: 30000,
//       completedTasks: 0,
//     },
//   ]);

//   // Debounce the search input
//   useEffect(() => {
//     const timerId = setTimeout(() => {
//       setDebouncedTerm(searchTerm);
//     }, 500); // 500ms delay for debouncing
//     return () => {
//       clearTimeout(timerId);
//     };
//   }, [searchTerm]);

//   useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         const response = await axios.post("/api/users", {
//           token: localStorage.getItem("token"),
//         });
//         if (response.status === 200) {
//           setUsers(response.data.users);

//           setLoading(false);
//         } else {
//           toast.error("Failed to fetch users");
//           setLoading(false);
//         }
//       } catch (error) {
//         toast.error("Error fetching users: " + error.message);
//         setLoading(false);
//       }
//     };
//     // fetchUsers();
//   }, []);

//   // Fetch users from backend based on search term (with token authentication)
//   useEffect(() => {
//     // Fetch users when debounced search term changes
//     fetchUsers();
//   }, [debouncedTerm]);

//   const fetchUsers = async () => {
//     try {
//       const response = await axios.post("/api/users/search", {
//         token: localStorage.getItem("token"),
//         searchTerm: debouncedTerm, // Send the debounced search term to backend
//       });

//       if (response.status === 200) {
//         setUsers(response.data.users);
//         setLoading(false);
//       } else {
//         toast.error("Failed to fetch users");
//         setLoading(false);
//       }
//     } catch (error) {
//       toast.error("Error fetching users: " + error.message);
//       setLoading(false);
//     }
//   };

//   // Open modal to edit user
//   const openModal = (user) => {
//     setEditingUser(user);
//     setShowModal(true);
//   };

//   // Close modal
//   const closeModal = () => {
//     setShowModal(false);
//     setEditingUser(null);
//   };

//   // Handle form submission for editing user details
//   const handleEditSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.put(`/api/users/${editingUser._id}`, {
//         ...editingUser,
//         token: localStorage.getItem("token"),
//       });
//       if (response.status === 200) {
//         toast.success("User updated successfully");
//         setShowModal(false);
//         fetchUsers();
//         // Optionally refetch users or update state
//       } else {
//         toast.error("Failed to update user");
//       }
//     } catch (error) {
//       toast.error("Error updating user: " + error.message);
//     }
//   };

//   if (loading) {
//     return (
//       <div className="flex h-screen items-center justify-center">
//         <p className="text-lg font-semibold text-gray-600">Loading users...</p>
//       </div>
//     );
//   }

//   return (
//     <div className="min-h-screen bg-gray-100 p-4">
//       <h2 className="mb-4 text-3xl font-bold text-purple-800">
//         User List{" "}
//         <span className="text-sm text-gray-600"> [{users?.length}] </span>
//       </h2>

//       {/* Search Input */}
//       <input
//         type="text"
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         className="mb-6 w-full rounded-md border border-gray-300 p-2"
//         placeholder="Search users..."
//       />

//       {/* User List */}
//       <div className="space-y-4">
//         {users?.map((user) => (
//           <div
//             key={user._id}
//             className="flex items-center justify-between rounded-lg bg-white p-4 shadow-md"
//           >
//             <div>
//               <p className="text-gray-600"> Name: {user.name}</p>
//               <p className="text-gray-600"> Email: {user.email}</p>

//               <p className="text-gray-600"> Mobile: {user.phone}</p>
//               <p className="text-gray-600"> Work Type: {user.workType}</p>
//               <p className="text-gray-600"> Plans: {user.plans[0].name}</p>
//               <p className="text-gray-600">
//                 {" "}
//                 Task : {user.plans[0].completedTasks}/{user.plans[0].tasks}
//               </p>
//               <p className="text-gray-600">
//                 {" "}
//                 Paid Amount For Buying Plans: {user.plans[0].price}
//               </p>
//               <p className="text-gray-600"> Password: {user.password}</p>
//               <p className="text-gray-600"> Amount: {user.amount}</p>
//               <p className="text-gray-600">
//                 {" "}
//                 Block Status: {user.isBlocked ? "Blocked" : "Active"}
//               </p>
//             </div>
//             <button
//               onClick={() => openModal(user)}
//               className="rounded-lg bg-blue-500 p-2 text-white hover:bg-blue-600"
//             >
//               <FaEdit className="inline-block" /> Edit
//             </button>
//           </div>
//         ))}
//       </div>

//       {/* Modal for Editing */}
//       {showModal && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//           <div className="w-full max-w-lg rounded-lg bg-white p-6 shadow-lg">
//             <h3 className="mb-4 text-2xl font-bold">Edit User</h3>
//             <form onSubmit={handleEditSubmit}>
//               <div className="mb-4">
//                 <label className="block text-gray-700">Name</label>
//                 <input
//                   type="text"
//                   value={editingUser.name}
//                   onChange={(e) =>
//                     setEditingUser({ ...editingUser, name: e.target.value })
//                   }
//                   className="w-full rounded-md border border-gray-300 p-2"
//                 />
//               </div>

//               <div className="mb-4">
//                 <label className="block text-gray-700">Password</label>
//                 <input
//                   type="password"
//                   value={editingUser.password}
//                   onChange={(e) =>
//                     setEditingUser({ ...editingUser, password: e.target.value })
//                   }
//                   className="w-full rounded-md border border-gray-300 p-2"
//                 />
//               </div>
//               <div className="mb-4">
//                 <label className="block text-gray-700">Amount</label>
//                 <input
//                   type="number"
//                   value={editingUser.amount}
//                   onChange={(e) =>
//                     setEditingUser({ ...editingUser, amount: e.target.value })
//                   }
//                   className="w-full rounded-md border border-gray-300 p-2"
//                 />
//               </div>
//               <div className="mb-4">
//                 <div className="w-full rounded-md border border-gray-300 p-2">
//                   {editingUser.plans.map((plan, index) => (
//                     <span key={index}>{plan.name}</span>
//                   ))}
//                 </div>
//                 <div className="text-sm text-green-600 cursor-pointer ">
//                   Upgrad Plan
//                 </div>
//                 <div className="w-full rounded-md border border-gray-300 p-2">
//                   {plans.map((plan, index) => (
//                     <span key={index}>{plan.name}</span>
//                   ))}
//                 </div>
//               </div>

//               <div className="mb-4">
//                 <label className="block text-gray-700">Block Status</label>

//                 <select
//                   value={editingUser.isBlocked === false ? "false" : "true"}
//                   onChange={(e) =>
//                     setEditingUser({
//                       ...editingUser,
//                       isBlocked: e.target.value === "true" ? true : false,
//                     })
//                   }
//                   className="w-full rounded-md border border-gray-300 p-2"
//                 >
//                   <option value="true">True</option>
//                   <option value="false">False</option>
//                 </select>
//               </div>

//               <div className="flex justify-between">
//                 <button
//                   type="submit"
//                   className="rounded-lg bg-green-500 px-4 py-2 text-white hover:bg-green-600"
//                 >
//                   Save
//                 </button>
//                 <button
//                   type="button"
//                   onClick={closeModal}
//                   className="rounded-lg bg-red-500 px-4 py-2 text-white hover:bg-red-600"
//                 >
//                   Cancel
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default UserList;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit } from "react-icons/fa"; // Icon for Edit
import { toast } from "react-toastify";

function UserList() {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [editingUser, setEditingUser] = useState(null); // User currently being edited
  const [showModal, setShowModal] = useState(false); // Modal state
  const [loading, setLoading] = useState(true);
   
  const [selectedPlan, setSelectedPlan] = useState(null); // Selected plan for the user
  // Debounce the search input
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 500); // 500ms delay for debouncing
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.post("/api/users", {
          token: localStorage.getItem("token"),
        });
        if (response.status === 200) {
          console.log(response.data);
          setUsers(response.data.users);
          setLoading(false);
        } else {
          toast.error("Failed to fetch users");
          setLoading(false);
        }
      } catch (error) {
        toast.error("Error fetching users: " + error.message);
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  // Fetch users from backend based on search term (with token authentication)
  // useEffect(() => {
  //   fetchUsers();
  // }, [debouncedTerm]);

  const fetchUsers = async () => {
    try {
      const response = await axios.post("/api/users/search", {
        token: localStorage.getItem("token"),
        searchTerm: debouncedTerm, // Send the debounced search term to backend
      });

      if (response.status === 200) {
        setUsers(response.data.users);
        setLoading(false);
      } else {
        toast.error("Failed to fetch users");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error fetching users: " + error.message);
      setLoading(false);
    }
  };

  // Open modal to edit user
  const openModal = (user) => {
    setEditingUser(user);
    setShowModal(true);
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setEditingUser(null);
  };

  // Handle form submission for editing user details
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(selectedPlan);
      const response = await axios.put(`/api/users/${editingUser._id}`, {
        ...editingUser,
        selectedPlan,
        token: localStorage.getItem("token"),
      });
      if (response.status === 200) {
        toast.success("User updated successfully");
        setShowModal(false);
        fetchUsers();
      } else {
        toast.error("Failed to update user");
      }
    } catch (error) {
      toast.error("Error updating user: " + error.message);
    }
  };

  // Handle upgrading or changing the plan
  const handlePlanChange = (newPlan) => {
    const updatedPlans = [{ ...newPlan, completedTasks: 0 }]; // Reset completed tasks
    setEditingUser({ ...editingUser, plans: updatedPlans });
  };

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <p className="text-lg font-semibold text-gray-600">Loading users...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <h2 className="mb-4 text-3xl font-bold text-purple-800">
        User List{" "}
        <span className="text-sm text-gray-600"> [{users?.length}] </span>
      </h2>

      {/* Search Input */}
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-6 w-full rounded-md border border-gray-300 p-2"
        placeholder="Search users..."
      />

      {/* User List */}
      <div className="space-y-4">
        {users?.map((user) => (
          <div
            key={user._id}
            className="flex items-center justify-between rounded-lg bg-white p-4 shadow-md"
          >
            <div>
              <p className="text-gray-600"> Name: {user.name}</p>
              <p className="text-gray-600"> Email: {user.email}</p>
              <p className="text-gray-600"> Mobile: {user.phone}</p>
              <p className="text-gray-600"> Work Type: {user.workType}</p>
              <p className="text-gray-600"> Plan: {user.currentPlan}</p>

              <p className="text-gray-600"> Password: {user.password}</p>
              <p className="text-gray-600"> Amount: {user.amount}</p>
              <p className="text-gray-600">
                Block Status: {user.isBlocked ? "Blocked" : "Active"}
              </p>
            </div>
            <button
              onClick={() => openModal(user)}
              className="rounded-lg bg-blue-500 p-2 text-white hover:bg-blue-600"
            >
              <FaEdit className="inline-block" /> Edit
            </button>
          </div>
        ))}
      </div>

      {/* Modal for Editing */}
      {showModal && editingUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-lg rounded-lg bg-white p-6 shadow-lg">
            <h3 className="mb-4 text-2xl font-bold">Edit User</h3>
            <form onSubmit={handleEditSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  value={editingUser.name}
                  onChange={(e) =>
                    setEditingUser({ ...editingUser, name: e.target.value })
                  }
                  className="w-full rounded-md border border-gray-300 p-2"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">Password</label>
                <input
                  type="password"
                  value={editingUser.password}
                  onChange={(e) =>
                    setEditingUser({ ...editingUser, password: e.target.value })
                  }
                  className="w-full rounded-md border border-gray-300 p-2"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">Amount</label>
                <input
                  type="number"
                  value={editingUser.amount}
                  onChange={(e) =>
                    setEditingUser({ ...editingUser, amount: e.target.value })
                  }
                  className="w-full rounded-md border border-gray-300 p-2"
                />
              </div>

              {/* Current Plan and Upgrade Option */}
              <label className="block w-full p-2 text-gray-700">
                Existing Plans
              </label>
              <div className="mb-4">
                <div className="mb-2 w-full rounded-md flex flex-wrap gap-2 border border-gray-300 p-2">
                  {editingUser.plans.map((plan, idx) => {
                    return (
                      <span
                        className={
                          idx === 0
                            ? "border border-green-500 py-1 rounded  px-2 text-sm"
                            : "border py-1 border-gray-500 rounded  px-2 text-sm"
                        }
                      >
                        {" "}
                        {plan.name}{" "}
                      </span>
                    );
                  })}
                </div>
                {/* <label className="block text-gray-700">Add Plan</label>
                <select
                  className="w-full rounded-md border border-gray-300 p-2"
                  onChange={(e) => {
                    const selectedPlanName = e.target.value;
                    const findPlanFromList = plans.find(
                      (plan) => plan.name === selectedPlanName
                    );

                    setSelectedPlan(findPlanFromList);
                  }}
                >
                  <option value="">Select Plan</option>
                  {plans.map((plan) => (
                    <option key={plan.name} value={plan.name}>
                      {plan.name}
                    </option>
                  ))}
                </select> */}
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">Block Status</label>
                <select
                  value={editingUser.isBlocked === false ? "false" : "true"}
                  onChange={(e) =>
                    setEditingUser({
                      ...editingUser,
                      isBlocked: e.target.value === "true",
                    })
                  }
                  className="w-full rounded-md border border-gray-300 p-2"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>

              <div className="flex justify-between">
                <button
                  type="submit"
                  className="rounded-lg bg-green-500 px-4 py-2 text-white hover:bg-green-600"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  className="rounded-lg bg-red-500 px-4 py-2 text-white hover:bg-red-600"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserList;
