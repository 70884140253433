import React, { useState } from "react";
import { FaWindowClose } from "react-icons/fa";
import { IoReorderThree } from "react-icons/io5";
import Logout from "../components/Logout";
import AddCustomer from "../components/AddCustomer";
import AddBankAccount from "../components/AddBankAccount";
import HomeAdmin from "../components/HomeAdmin";
import UserPaymentRequest from "../cards/UserPaymentRequest";
import BulkPaymentRequest from "./BulkPaymentRequest";
import PlaneChange from "../components/PlaneChange";
import SupportTicket from "../components/SupportTicket";
import EditPaymentUPI from "../cards/EditPaymentUPI";
import Users from "../components/Users";
const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("Home");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const tabs = [
    "Home",
    "Agent",
    "Payment Request",
    "Edit Payment UPI",
    "PlanChange",
    "Ticket",
    "Logout",
  ];

  const renderContent = () => {
    switch (activeTab) {
      case "Home":
        return <HomeAdmin isAdminView={true} />;

      case "Payment Request":
        return <UserPaymentRequest />;
      case "Agent":
        return <Users />;
      case "PlanChange":
        return <PlaneChange />;
      case "Edit Payment UPI":
        return <EditPaymentUPI />;

      case "Add Bank Account":
        return <AddBankAccount />;
      case "Add Customer":
        return <AddCustomer />;
      case "Ticket":
        return <SupportTicket />;
      case "Logout":
        return <Logout />;
      default:
        return <div>Welcome to the Dashboard</div>;
    }
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar for Desktop */}
      <div className="md:!block !hidden h-full w-64 bg-gray-800 text-white md:flex md:flex-col">
        <h1 className="border-b-2 border-gray-700 p-2 text-2xl font-bold">
          Admin Panel
        </h1>
        <ul>
          {tabs.map((tab) => (
            <li
              key={tab}
              className={`cursor-pointer p-4 hover:bg-gray-700 ${
                activeTab === tab ? "bg-gray-700" : ""
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>

      {/* Mobile Sidebar Toggle Button */}
      <div className="flex h-6 w-5 items-center justify-center bg-gray-800 p-3 text-white md:hidden">
        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className="text-white focus:outline-none"
        >
          {sidebarOpen ? <FaWindowClose /> : <IoReorderThree />}
        </button>
      </div>

      {/* Mobile Sidebar (Slide-in) */}
      <div
        className={`fixed left-0 top-0 z-50 h-full w-64 transform space-y-4 bg-gray-800 p-4 text-white transition-transform md:hidden ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <h1 className="border-b-2 border-gray-700 pb-2 text-2xl font-bold">
          Admin Panel
        </h1>
        <ul>
          {tabs.map((tab) => (
            <li
              key={tab}
              className={`cursor-pointer p-4 hover:bg-gray-700 ${
                activeTab === tab ? "bg-gray-700" : ""
              }`}
              onClick={() => {
                setActiveTab(tab);
                setSidebarOpen(false); // Close sidebar after selecting a tab
              }}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>

      {/* Overlay for mobile when sidebar is open */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 z-40 bg-black opacity-50 md:hidden"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}

      {/* Content Area */}
      <div className="h-full flex-1 overflow-y-auto ">
        <h2 className="mb-4 text-2xl font-bold">{activeTab}</h2>
        <div className="h-full rounded-lg bg-white p-4 shadow-lg">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
